import React from 'react'
import styled from '@emotion/styled'
import {Card} from './'
import {Draggable} from 'react-beautiful-dnd'

interface Props {
  card: Card
  index: number
  cardProgress: {doing: number; done: number}
  getCardById: (id: string) => Card | undefined
}

const CardContainer = styled.div({
  backgroundColor: '#FFFFFF',
  borderRadius: 3,
  boxShadow: '0px 1px 0px rgba(9,30,66,0.25)',
  padding: '9px 8px 8px',
  margin: '0 8px 8px',
})

const Title = styled.span({
  display: 'block',
  marginBottom: 6,
  fontSize: 14,
  fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif',
  lineHeight: 1.2,
  userSelect: 'none',
})

const PeteTheInvader = ({isParent}: {isParent?: boolean}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14px"
    height="14px"
    viewBox="0 0 14 14"
    style={{flex: '0 0 auto'}}
  >
    {isParent && (
      <path
        d="M0 1a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1z"
        fill="#FFFFFF"
        strokeWidth="1.167"
        stroke="hsl(150, 59%, 76%)"
      />
    )}
    <path
      d="M10 8h2v2h-2V8.002H8.002v1.994h-2V6.002H10zm-3.998 2H10v2H6.002zM4 7.999V6.002h2v2H4v1.997H2v-2zM2 2h2v2H2zm2 8h2v2H4zm2-8h2v2H6zm4 0h2v2h-2z"
      fill="#33bf7a"
    />
  </svg>
)

interface DetailsProps {
  cardProgress: {doing: number; done: number}
  card: Card
  parent?: Card
}

const Details: React.FunctionComponent<DetailsProps> = ({
  cardProgress,
  card,
  parent,
}) => {
  const {doing, done} = cardProgress
  const isDone = doing + done === done
  const isParent = card.parentId === null

  return (
    <div
      css={{
        display: 'inline-flex',
        alignItems: 'start',
        backgroundColor: isParent && isDone ? '#61BD4F' : 'transparent',
        padding: isParent ? '3px 4px' : '3px 0px',
        borderRadius: 2,
      }}
    >
      <PeteTheInvader isParent={isParent} />
      {isParent ? (
        <span
          css={{
            color: isDone ? '#FFFFFF' : '#5e6c84',
            fontSize: 12,
            display: 'inline-block',
            marginLeft: 5,
            fontFamily: 'Helvetica Neue, Arial, Helvetica, sans-serif',
            userSelect: 'none',
          }}
        >{`${done}/${doing + done} done`}</span>
      ) : (
        <div
          css={{
            color: '#5e6c84',
            borderRadius: 3,
            fontSize: 12,
            marginLeft: 5,
            fontFamily: 'Helvetica Neue, Arial, Helvetica, sans-serif',
            userSelect: 'none',
          }}
        >
          {parent && parent.title}
        </div>
      )}
    </div>
  )
}

const TrelloCard = ({card, index, cardProgress, getCardById}: Props) => (
  <Draggable draggableId={card.id} index={index}>
    {(provided) => (
      <CardContainer
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
      >
        <Title>{card.title}</Title>
        <Details
          cardProgress={cardProgress}
          card={card}
          parent={
            card.parentId !== null ? getCardById(card.parentId) : undefined
          }
        />
      </CardContainer>
    )}
  </Draggable>
)

export default TrelloCard
