import React from 'react'

import Headline from '../Headline'
import Wrapper from 'components/Wrapper'
import styled from '@emotion/styled'
import {typography, breakpoint, margin, colors, padding} from 'styles'
import spacing from 'styles/constants/spacing'
import useSiteMetadata from 'hooks/useSiteMetadata'
import {trackOutboundLink} from 'analytics'

const Grid = styled.div({
  ...margin.top(36),
  ...padding(16),
  borderRadius: spacing(24),
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridRowGap: spacing(36),
  backgroundColor: colors.primary[0],
  maxWidth: 'none',
  [breakpoint.mediumAndUp]: {
    ...margin.top(48),
    ...padding(0),
    ...padding.y(24),
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
})

const Cell = styled.div({
  [breakpoint.mediumAndUp]: {
    ...padding.x(24),
  },
})

const CellTitle = styled.h3({
  ...margin.bottom(8),
  ...typography.headline4,
  textAlign: 'center',
  [breakpoint.mediumAndUp]: {
    ...margin.bottom(16),
    ...typography.headline3,
  },
})

const CellBody = styled.p({
  ...typography.body,
  textAlign: 'center',
})

const StyledA = styled.a({
  color: colors.primary[60],
})

const HowToGetStarted: React.FunctionComponent = () => {
  const {appUrls} = useSiteMetadata()

  return (
    <Wrapper>
      <Headline centered>How to get started</Headline>
      <Grid>
        <Cell>
          <CellTitle>➕ Add</CellTitle>
          <CellBody>
            <StyledA
              href={appUrls.enablePowerUpUrl}
              onClick={() =>
                trackOutboundLink('Add to Trello', appUrls.enablePowerUpUrl)
              }
            >
              Click here
            </StyledA>{' '}
            to add Hello&nbsp;Epics to a Trello&nbsp;board.
          </CellBody>
        </Cell>
        <Cell>
          <CellTitle>🔗 Connect</CellTitle>
          <CellBody>
            Go to the board you selected and click “Get started with
            Hello&nbsp;Epics” in the upper right-hand corner.
          </CellBody>
        </Cell>
        <Cell>
          <CellTitle>🏁 Select</CellTitle>
          <CellBody>
            Start your 14-day free trial or select a&nbsp;paid&nbsp;plan.
          </CellBody>
        </Cell>
      </Grid>
    </Wrapper>
  )
}

export default HowToGetStarted
