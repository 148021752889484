import React from 'react'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import 'styles/carouselOverrides.css'
import {Carousel} from 'react-responsive-carousel'
import styled from '@emotion/styled'
import {padding, margin, breakpoint, colors} from 'styles'
import CustomerQuote, {QuoteProps} from './CustomerQuote'

interface QuoteCarouselProps {
  quotes: QuoteProps[]
}

const Slide = styled.div({
  ...padding(36),
  [breakpoint.mediumAndUp]: {
    ...padding.x(64),
    ...padding.y(36),
  },
})

interface DotProps {
  isSelected: boolean
}

const Dot = styled.div<DotProps>(({isSelected}) => ({
  ...margin(6 as any),
  display: 'block',
  borderRadius: '100%',
  backgroundColor: colors.primary[isSelected ? 20 : 50],
  width: 10,
  height: 10,
  lineHeight: 10,
}))

const renderIndicator = (
  clickHandler: (e: React.MouseEvent | React.KeyboardEvent) => void,
  isSelected: boolean,
  index: number,
  _label: string
) => (
  <button aria-label={`Select quote ${index + 1}`} onClick={clickHandler}>
    <Dot isSelected={isSelected} />
  </button>
)

const QuoteCarousel: React.FunctionComponent<QuoteCarouselProps> = ({
  quotes,
}) => {
  return (
    <Carousel
      swipeable
      emulateTouch
      showStatus={false}
      showThumbs={false}
      showArrows={false}
      renderIndicator={renderIndicator}
    >
      {quotes.map((quote, index) => (
        <Slide key={index}>
          <CustomerQuote {...quote} />
        </Slide>
      ))}
    </Carousel>
  )
}

export default QuoteCarousel
