import {QuoteProps} from 'components/QuoteCarousel/CustomerQuote'
import {graphql, useStaticQuery} from 'gatsby'
import {UseCustomerQuotesQuery} from 'landing-gatsby-graphql-generated'
import invariant from 'tiny-invariant'

const useCustomerQuotes = () => {
  const data = useStaticQuery<UseCustomerQuotesQuery>(graphql`
    query UseCustomerQuotes {
      motoRadLogo: file(relativePath: {eq: "customers/moto-rad.png"}) {
        ...AvatarImage
      }

      gartnerLogo: file(relativePath: {eq: "customers/gartner.png"}) {
        ...AvatarImage
      }

      paddlLogo: file(relativePath: {eq: "customers/paddl.png"}) {
        ...AvatarImage
      }
    }
  `)

  const gartnerLogo = data.gartnerLogo?.childImageSharp?.fixed
  const motoRadLogo = data.motoRadLogo?.childImageSharp?.fixed
  const paddlLogo = data.paddlLogo?.childImageSharp?.fixed

  invariant(gartnerLogo, 'expected to find gatner logo')
  invariant(motoRadLogo, 'expected to find motoRad logo')
  invariant(paddlLogo, 'expected to find paddl logo')

  const quotes: QuoteProps[] = [
    {
      text:
        'Hello Epics solved many of my struggles by providing such an awesome integration into Trello; it was exactly what I needed to be as productive as I knew I could be.',
      credit: {
        name: 'Justin Nixon',
        company: {
          name: 'Gartner',
          url: 'https://gartner.com',
          logoSrc: gartnerLogo,
        },
      },
    },
    {
      text:
        'Hello Epics has quickly become my most useful Power-Up — it’s revolutionized the way I work!',
      credit: {
        name: 'Emily Woods',
        company: {
          name: 'MotoRad',
          url: 'https://motorad.com',
          logoSrc: motoRadLogo,
        },
      },
    },
    {
      text:
        'Our engineering team uses sprints, and we transferred the work methodology into other areas of the business (like customer success and marketing) using Hello Epics.',
      credit: {
        name: 'Judith Baeta',
        company: {
          name: 'Paddl',
          url: 'https://paddljobs.com/',
          logoSrc: paddlLogo,
        },
      },
    },
  ]

  return quotes
}

export default useCustomerQuotes
