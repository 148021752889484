import React from 'react'
import styled from '@emotion/styled'
import {typography, colors, margin, breakpoint} from 'styles'
import {graphql} from 'gatsby'
import Img from 'gatsby-image'
import {GatsbyImageSharpFixed_WithWebpFragment} from 'landing-gatsby-graphql-generated'

export interface QuoteProps {
  text: string
  credit: {
    name?: string
    company: {
      name: string
      url: string
      logoSrc: GatsbyImageSharpFixed_WithWebpFragment
    }
  }
}

const Blockquote = styled.blockquote({
  ...margin.bottom(8),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})

const Text = styled.p({
  ...typography.headline3,
  fontWeight: 400,
  fontStyle: 'italic',
  color: colors.textLight,
  [breakpoint.mediumAndUp]: {
    ...typography.headline2,
  },
})

const Attribution = styled.footer({
  ...margin.top(16),
  ...typography.caption,
  display: 'flex',
  alignItems: 'center',
  fontStyle: 'normal',
  [breakpoint.mediumAndUp]: {
    ...typography.body,
  },
})

type AvatarProps = Pick<QuoteProps, 'credit'>

export const query = graphql`
  fragment AvatarImage on File {
    childImageSharp {
      fixed(width: 32, height: 32, quality: 90) {
        ...GatsbyImageSharpFixed_withWebp
      }
    }
  }
`

const AVATAR_SIZE = 32 as const

const Avatar: React.FunctionComponent<AvatarProps> = ({credit}) => {
  return (
    <Img
      css={{
        ...margin.left(4),
        width: AVATAR_SIZE,
      }}
      alt={`${credit.name} from ${credit.company.name}`}
      fixed={credit.company.logoSrc as any}
    />
  )
}

const CustomerQuote: React.FunctionComponent<QuoteProps> = ({text, credit}) => (
  <Blockquote cite={credit && credit.company.url}>
    <Text>&ldquo;{text}&rdquo;</Text>
    <Attribution>
      &mdash;{' '}
      <span>{`${credit.name}${credit.name ? ', ' : ''}${
        credit.company.name
      }`}</span>
      <Avatar credit={credit} />
    </Attribution>
  </Blockquote>
)

export default CustomerQuote
