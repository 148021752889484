import React from 'react'
import styled from '@emotion/styled'
import QuoteCarousel from 'components/QuoteCarousel'
import {QuoteProps} from 'components/QuoteCarousel/CustomerQuote'
import Headline from 'components/home/Headline'
import {padding, margin} from 'styles'

export interface QuoteCarouselBladeProps {
  quotes: QuoteProps[]
  title: string
}

// Normally we'd use margin.bottomBlade() but need to account for the margin on the dots.
const CarouselWrapper = styled.div({
  marginBottom: -4,
  maxWidth: 1200,
  ...padding.x(24),
  ...margin.x('auto'),
})

const QuoteCarouselBlade: React.FunctionComponent<QuoteCarouselBladeProps> = ({
  quotes,
  title,
}) => (
  <CarouselWrapper>
    <Headline centered>{title}</Headline>
    <QuoteCarousel quotes={quotes} />
  </CarouselWrapper>
)

export default QuoteCarouselBlade
