import styled from '@emotion/styled'
import {typography, margin, breakpoint} from 'styles'

interface Props {
  centered?: boolean
}

const Headline = styled.h2<Props>((props) => ({
  ...typography.headline3,
  ...margin.bottom(16),
  textAlign: props.centered ? 'center' : 'left',
  [breakpoint.smallAndUp]: {
    ...typography.headline2,
  },
}))

export default Headline
