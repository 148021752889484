import React from 'react'
import styled from '@emotion/styled'
import {typography, colors, margin, padding} from 'styles'
import AddToTrelloButton from 'components/AddToTrelloButton'
import HelloEpicsDemo from 'components/HelloEpicsDemo'
import {trackOutboundLink} from 'analytics'
import useSiteMetadata from 'hooks/useSiteMetadata'
import Wrapper from './Wrapper'

export interface DemoBladeProps {
  title: string
  subtitle: string
}

const IntroContainer = styled.div({
  ...padding.top('calc(12vh - 36px)'),
  position: 'relative',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
})

const Heading = styled.h1({
  ...typography.headline1,
  ...margin.bottom(24),
  '@media(min-width: 32rem)': {
    ...typography.display,
  },
})

const DemoBlade: React.FunctionComponent<DemoBladeProps> = ({
  title,
  subtitle,
}) => {
  const {appUrls} = useSiteMetadata()
  return (
    <Wrapper>
      <IntroContainer>
        <div
          css={{flex: '1 1 33%', ...margin.bottom(48), ...padding.right(16)}}
        >
          <Heading>{title}</Heading>
          <p
            css={{...typography.body, ...margin.bottom(16), color: colors.text}}
          >
            {subtitle}
          </p>
          <div css={{display: 'flex', alignItems: 'center'}}>
            <AddToTrelloButton />
            <a
              href={appUrls.enablePowerUpUrl}
              onClick={() =>
                trackOutboundLink('Add to Trello', appUrls.enablePowerUpUrl)
              }
              css={{
                ...margin.left(16),
                ...margin.bottom(4),
                ...typography.caption,
                display: 'inline-block',
                fontWeight: 700,
                textDecoration: 'none',
                color: 'inherit',
              }}
            >
              Try free for 14 days
            </a>
          </div>
        </div>
        <HelloEpicsDemo />
      </IntroContainer>
    </Wrapper>
  )
}

export default DemoBlade
