import React, {Component} from 'react'
import {
  DragDropContext,
  DropResult,
  DraggableLocation,
} from 'react-beautiful-dnd'
import TrelloList from './TrelloList'
import {margin, padding} from 'styles'
import {trackDemoEvent} from 'analytics'

export interface Card {
  id: string
  title: string
  parentId: string | null
}

export interface List {
  id: string
  title: string
  cards: Card[]
}

interface State {
  lists: List[]
}

const reorder = (list: List, startIndex: number, endIndex: number) => {
  const result = Array.from(list.cards)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  trackDemoEvent('Reordered Card', removed.id)

  return result
}

const move = (
  source: List,
  destination: List,
  droppableSource: DraggableLocation,
  droppableDestination: DraggableLocation
) => {
  const sourceClone = {...source}
  const destClone = {...destination}
  const [removed] = sourceClone.cards.splice(droppableSource.index, 1)

  destClone.cards.splice(droppableDestination.index, 0, removed)

  trackDemoEvent(
    'Moved Card',
    `${removed.id} from ${source.id} to ${destination.id}`
  )
  if (destination.id === 'done') {
    trackDemoEvent('Completed Card', removed.id)
  }

  return {srcList: sourceClone, destList: destClone}
}

class HelloEpicsDemo extends Component<{}, State> {
  state: State = {
    lists: [
      {
        id: 'doing',
        title: 'Doing',

        cards: [
          {id: 'epic', title: 'Organize all-hands meeting', parentId: null},
          {id: 'item-1', title: 'Create a slidedeck', parentId: 'epic'},
        ],
      },
      {
        id: 'done',
        title: 'Done',
        cards: [
          {id: 'item-2', title: 'Set a date', parentId: 'epic'},
          {id: 'item-3', title: 'Design the agenda', parentId: 'epic'},
          {id: 'item-4', title: 'Invite speakers to present', parentId: 'epic'},
          {
            id: 'item-5',
            title: 'Reserve tech support from IT',
            parentId: 'epic',
          },
          {
            id: 'item-6',
            title: 'Collect questions for Q&A session',
            parentId: 'epic',
          },
        ],
      },
    ],
  }

  onDragEnd = (result: DropResult) => {
    const {source, destination} = result

    // dropped outside the list
    if (!destination) {
      return
    }

    if (source.droppableId === destination.droppableId) {
      const {list, index} = this.getList(source.droppableId)
      const items = reorder(list, source.index, destination.index)

      this.setState((prevState) => {
        const lists = [...prevState.lists]
        lists[index].cards = items
        return {lists}
      })
    } else {
      const {list: srcList, index: srcIndex} = this.getList(source.droppableId)
      const {list: destList, index: destIndex} = this.getList(
        destination.droppableId
      )
      const movedLists = move(srcList, destList, source, destination)

      this.setState((prevState) => {
        const lists = [...prevState.lists]
        lists[srcIndex] = movedLists.srcList
        lists[destIndex] = movedLists.destList
        return {lists}
      })

      const {doing} = this.getCardProgress()

      if (doing === 0) {
        if (!window) {
          return
        }
        trackDemoEvent('Completed Epic')

        import('canvas-confetti').then(({default: confetti}) => {
          confetti({
            angle: 135,
            decay: 0.95,
            particleCount: 300,
            origin: {
              x: 1,
              y: 0.5,
            },
            zIndex: -1,
          })

          if (window.navigator && window.navigator.vibrate) {
            window.navigator.vibrate(200)
          }
        })
      }
    }
  }

  getList: (id: string) => {list: List; index: number} = (id) => {
    const index = this.state.lists.findIndex((l) => l.id === id)
    if (index < 0) {
      throw new Error('List not found')
    }
    const list = this.state.lists[index]
    return {list, index}
  }

  getCardProgress = () => {
    const countCards = (count: number, card: Card) => {
      if (card.parentId === null) {
        return count
      }
      return count + 1
    }

    return this.state.lists.reduce(
      (count, l) => {
        if (l.id === 'done') {
          count.done += l.cards.reduce(countCards, 0)
        } else {
          count.doing += l.cards.reduce(countCards, 0)
        }

        return count
      },
      {doing: 0, done: 0}
    )
  }

  getCardById = (id: string) => {
    let card: Card | undefined
    this.state.lists.some((l) => {
      card = l.cards.find((c) => c.id === id)
      return !!card
    })

    if (!card) {
      // eslint-disable-next-line no-console
      console.warn(`Card not found with id: ${id}`)
    }

    return card
  }

  render() {
    const cardProgress = this.getCardProgress()

    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <div
          css={{
            ...margin.x(-24),
            ...padding.x(24),
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            overflowX: 'auto',
          }}
        >
          {this.state.lists.map((list) => (
            <TrelloList
              cardProgress={cardProgress}
              list={list}
              getCardById={this.getCardById}
              key={list.id}
            />
          ))}
        </div>
      </DragDropContext>
    )
  }
}

export default HelloEpicsDemo
