import React, {Fragment} from 'react'
import styled from '@emotion/styled'
import {List, Card} from './'
import TrelloCard from './TrelloCard'
import {Droppable} from 'react-beautiful-dnd'
import {breakpoint, margin} from 'styles'

interface Props {
  list: List
  cardProgress: {doing: number; done: number}
  getCardById: (id: string) => Card | undefined
}

const Column = styled.div({
  backgroundColor: '#EBECF0',
  borderRadius: 3,
  display: 'flex',
  flexDirection: 'column',
  margin: '0 4px',
})

const ListHeader = styled.span({
  fontWeight: 900,
  fontSize: 14,
  lineHeight: 1.42,
  padding: '14px 15px 12px',
  fontFamily: 'Helvetica Neue, Arial, Helvetica, sans-serif',
  userSelect: 'none',
})

const ArrowIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100"
    height="100"
    style={{
      flex: '0 0 auto',
    }}
  >
    <g fill="transparent" strokeWidth="2" stroke="#5b5e6d">
      <path
        d="M79.798 62.635s-.523.113-1.473.266M75.38 63.326c-13.47 1.66-53.188 3.136-54.22-37.033M21.137 24.79c0-.493.004-.994.014-1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M69.058 73.853l10.74-11.217-13.222-4.926"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
)

const TrelloList = ({list, cardProgress, getCardById}: Props) => (
  <Droppable droppableId={list.id}>
    {(provided) => (
      <div
        ref={provided.innerRef}
        css={{
          ':last-child': {
            position: 'relative',
            '&:after': {
              content: '""',
            },
          },
        }}
      >
        <Column>
          <ListHeader>{list.title}</ListHeader>
          {list.cards.map((card, index) => (
            <TrelloCard
              key={card.id}
              card={card}
              index={index}
              cardProgress={cardProgress}
              getCardById={getCardById}
            />
          ))}
          {provided.placeholder}
        </Column>
        {list.id === 'doing' && (
          <div
            css={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-end',
              flexDirection: 'column-reverse',
              [breakpoint.smallAndUp]: {
                alignItems: 'center',
                justifyContent: 'flex-end',
                flexDirection: 'row',
              },
            }}
          >
            {cardProgress.doing > 0 && (
              <Fragment>
                <span
                  css={{
                    ...margin.right(16),
                    fontFamily: "'Indie Flower', cursive",
                    fontSize: 18,
                    textAlign: 'right',
                    userSelect: 'none',
                    [breakpoint.smallAndUp]: {
                      ...margin.right(0),
                    },
                  }}
                >
                  <span aria-label="waving" role="img">
                    👋
                  </span>{' '}
                  Try it out!
                </span>
                <ArrowIcon />
              </Fragment>
            )}
          </div>
        )}
      </div>
    )}
  </Droppable>
)

export default TrelloList
