import styled from '@emotion/styled'
import {margin, breakpoint, padding} from 'styles'
import {Spacing} from 'styles/constants/spacing'

export interface PageWrapperProps {
  smallSpacing: Spacing
  mediumAndUpSpacing: Spacing
}

const PageWrapper = styled.div<PageWrapperProps>(
  ({smallSpacing, mediumAndUpSpacing}) => ({
    ...margin.x('auto'),
    ...padding.x('auto'),
    '>:not(:last-child)': {
      ...margin.bottom(smallSpacing),
      [breakpoint.mediumAndUp]: {
        ...margin.bottom(mediumAndUpSpacing),
      },
    },
  })
)

export default PageWrapper
